import * as React from "react";

import ContactForm from "../components/Contact/ContactForm";
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";
import { SvgBackground } from "../components/Base/SvgBackground";

const ContactUs = () => {
   return (
    <Layout>
      <SvgBackground position="absolute" bottom="0" right="0" display={{base: "none", lg: "block"}}></SvgBackground>
      <ContactForm></ContactForm>
    </Layout>
  );
}

export default ContactUs

export const Head = () => (
  <SEO title="AceMedical Wholesale | Contact Us" description="Have more questions about our medical wholesale company? Contact us here." />
)
